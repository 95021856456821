<template>
  <div class="employees m-4 pb-5">
    <Breadcrumb v-bind:data="this.$props.breadcrumb" />
    <div class="larkin-enclosure-box w-100 mb-3 desktop-width-only-xs">
      <div v-for="error in errors" v-bind:key="error">
        {{error.message}}
      </div>

      <b-form @submit.stop.prevent="onSubmit" class="remove-validate-treatment">
        <b-form-row class="mb-2">
          <b-col class="col-md-2">
            <b-form-group label="Last Name" label-for="lastname" label-class="mb-0">
              <b-form-input v-model="$v.search.lastName.$model" id="lastname" aria-describedby="lastname-feedback" :state="validateState('lastName')"></b-form-input>
              <b-form-invalid-feedback id="lastname-feedback" class="mb-2">At least 1 field is required.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col class="col-md-2">
            <b-form-group label="First Name" label-for="firstname" label-class="mb-0">
              <b-form-input v-model="$v.search.firstName.$model" id="firstname" class=""></b-form-input>
            </b-form-group>
          </b-col>

          <b-col class="col-md-2">
            <b-form-group label="Email" label-for="email" label-class="mb-0">
              <b-form-input v-model="$v.search.email.$model" id="email" aria-describedby="email-feedback" :state="validateState('email')" class=""></b-form-input>
              <b-form-invalid-feedback id="email-feedback">Incorrect format.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col class="col-md-2">
            <b-form-group label="Employee ID" label-for="employeeId" label-class="mb-0">
              <b-form-input v-model="$v.search.employeeId.$model" id="employeeId" aria-describedby="employeeid-feedback"></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <button type="submit" :disabled="searchDataHasNoCriteria(this.search)" class="btn-search btn-larkin-yellow px-4">Search</button>
          <button type="reset" :disabled="searchDataHasNoCriteria(this.search)"  v-on:click="clearData" class="btn-search btn-larkin-outline px-4">Clear</button>
<!--          <b-btn type="submit" :disabled="searchDataHasNoCriteria(this.search)" class="btn-larkin btn-search btn-larkin-yellow px-4">Search</b-btn>-->
<!--          <b-btn type="reset" :disabled="searchDataHasNoCriteria(this.search)"  v-on:click="clearData" class="btn-larkin btn-search btn-larkin-outline px-4">Clear</b-btn>-->
        </b-form-row>

      </b-form>
    </div>

    <div v-if="result.total > result.pageSize" class="global-invalid-feedback">Your search produced {{result.total}} results, but has been limited to {{result.pageSize}} results.</div>
    <div class="larkin-enclosure-box desktop-width-only-sm" v-if="submitted">
      <b-table v-if="result.total" hover :items="result.employees" :fields="fields" @row-clicked="selectEmployee" class="table-row-click"></b-table>
      <div v-else-if="!result.total && submitted"><h6>No Employees found.</h6></div>
    </div>
  </div>
</template>

<script>
  import Breadcrumb from '@/components/Breadcrumb.vue'
  import ApiProperties from '../../../src/main/resources/application'
  import { validationMixin } from "vuelidate"
  import formMixin from '@/mixins/formMixin'
  import { requiredUnless, email, minLength, maxLength } from "vuelidate/lib/validators"
  import get from "just-safe-get"

  const localStorageKey = 'employeeSearch'
  const localStorageOptions = {
    ttl: 60 * 60 * 1000 // 1 hour
  }

  const searchFormData = {
    clientId: "",
    firstName: "",
    lastName: "",
    email: "",
    employeeId: ""
  };

  export default {
    name: 'employees',
    components: {Breadcrumb},
    props: ['breadcrumb'],
    mixins: [validationMixin, formMixin],
    data: function() {
      return {
        advancedSearch: false,
        search: {},
        fields: [
          {key: 'lastName', sortable: true},
          {key: 'firstName', sortable: true},
          {key: 'email'},
          {key: 'employeeId', sortable: true},
          {key: 'phone'},
          {key: 'administrator', sortable: true}
        ],
        result: {
          total: 0,
          pageSize: 0,
          employees: []
        },
        errors: []
      }
    },
    validations: {
      search: {
        lastName: {
          required: requiredUnless(function(search){
            return (search.firstName || search.email || search.employeeId)
          })
        },
        firstName: {
        },
        email: {
          email
        },
        employeeId: {}
      }
    },
    methods: {
      initSearch() {
        this.$storage.setOptions(localStorageOptions)
        this.search = this.$storage.get(localStorageKey, Object.assign({}, searchFormData))

        // initiate a search (this will do nothing if there is no data or no storage key)
        this.submitOnInit()
      },

      validateState(name) {
        const { $dirty, $error } = this.$v.search[name]
        return $dirty ? !$error : null
      },

      handleError: function(e) {
        let error = get(e, 'response.data');

        if (error) {
          this.result.total = 0;
          this.result.employees = []
          this.errors.push(error)
        }
      },

      searchDataHasNoCriteria: function(searchData) {
        return Object.values(searchData).every(val => {
          return (val===undefined || val==="")
        })
      },

      submitOnInit() {
        if (this.searchDataHasNoCriteria(this.search)) {
          this.$storage.remove(localStorageKey)

          return
        }

        this.onSubmit()
      },

      onSubmit() {
        if (this.searchDataHasNoCriteria(this.search)) {
          this.$v.search.$touch()
          this.$storage.remove(localStorageKey)
          return;
        }

        this.submit('post', ApiProperties.api.employeeSearch, {}, this.search, (response) => {
          this.result.total = (response.data.total) ? response.data.total : 0
          this.result.pageSize = (response.data.pageSize) ? response.data.pageSize : 0
          this.result.employees = response.data.results

          // we have valid results, save the search params to local storage
          this.$storage.set(localStorageKey, this.search)
        }, (e) => {
          this.handleError(e)
        });
      },

      clearData() {
        this.search = Object.assign({}, searchFormData)
        this.submitted = false
        this.$v.search.$reset()
        this.$storage.remove(localStorageKey)
      },

      selectEmployee(record) {
        this.$router.push({ name: 'employeeDetail', params: { employeeId: record.masterEmployeeId } })
      }
    },

    created() {
      this.initSearch()
    }
  }
</script>
<style lang="scss">
.employees {}
.btn-search{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  padding: 10px 24px;
  gap: 8px;
  margin: 7px;
  border-radius: 1px !important;
  &:enabled:active {
    color: #212529 !important;
  }
  &:disabled{
    padding: 11px 28px;
  }
}
</style>
